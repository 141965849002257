<template>
  <v-form ref="filterForm" @submit.prevent="submitFilter()">
    <v-row dense>
      <v-col cols="12" md="2">
        <input-date
          v-model="filters.startTime"
          :label="$t('startTime')"
          :infoText="'Data inicial da ocorrência'"
          :rules="[requiredRule, dateLength]"
          dense
        />
      </v-col>
      <v-col cols="12" md="2">
        <input-date
          v-model="filters.finishTime"
          :label="$t('finishTime')"
          :infoText="'Data final da ocorrência'"
          :rules="[
            requiredRule,
            dateLength,
            dataIntervalMustBeLessThanDays(filters.startTime, filters.finishTime, searchDateLimitDays),
          ]"
          dense
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="filters.companyCode"
          :items="companyCodeList"
          :label="filtroEmpresa"
          :rules="[requiredRule]"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12" md="2">
        <input-date
          v-model="filters.altStartTime"
          :label="$t('altStartTime')"
          :infoText="'Data inicial da inclusão da ocorrência'"
          dense
          clearable
          :rules="[altStartTimeRequired, dateLength]"
        />
      </v-col>
      <v-col cols="12" md="2">
        <input-date
          v-model="filters.altFinishTime"
          :label="$t('altFinishTime')"
          :infoText="'Data final da inclusão da ocorrência'"
          :rules="[
            altFinishTimeRequired,
            dateLength,
            dataIntervalMustBeLessThanDays(filters.altStartTime, filters.altFinishTime, 0),
          ]"
          dense
          clearable
        />
      </v-col>
      <v-col cols="12" md="5">
        <v-autocomplete
          v-model="filters.nfeNumber"
          :label="$t('nfNumber')"
          :items="filters.nfeNumber"
          @paste.prevent="pasteData($event, 'nfeNumber')"
          @keydown.space="pushManualValue($event, 'nfeNumber')"
          class="multipleDocs"
          @click:clear="filters.nfeNumber = []"
          outlined
          clearable
          multiple
          chips
          deletable-chips
          small-chips
          :allow-overflow="false"
          :no-data-text="'Informe as notas fiscais'"
          dense
          :append-icon="''"
          menu-props="closeOnContentClick"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="5">
            <v-btn depressed color="#D8D8D8" :block="$vuetify.breakpoint.xsOnly" type="submit">
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <TLoading v-model="loading" />
  </v-form>
</template>

<script>
import mixinFilters from '@/mixins/mixinFilter'
import mixinRules from '@/mixins/mixinRules'
import InputDate from '@/components/InputDate.vue'

export default {
  name: 'FilterComponent',
  mixins: [mixinFilters, mixinRules],
  components: {
    InputDate,
  },
  data() {
    return {
      path: '',
      searchDateLimitDays: 30,
      filters: {
        startTime: '',
        finishTime: '',
        altStartTime: '',
        altFinishTime: '',
        companyCode: '',
        nfeNumber: [],
      },
    }
  },
  computed: {
    altStartTimeRequired() {
      if (!this.filters.altStartTime && this.filters.altFinishTime) {
        return 'Este campo é obrigatório'
      }
      return true
    },
    altFinishTimeRequired() {
      if (this.filters.altStartTime && !this.filters.altFinishTime) {
        return 'Este campo é obrigatório'
      }
      return true
    },
  },
  methods: {
    prepareFilter() {
      const filter = {}
      this.verifyNullableFilter(filter, 'startTime', `${this.filters.startTime}T00:00:00-03:00`)
      this.verifyNullableFilter(filter, 'finishTime', `${this.filters.finishTime}T23:59:59-03:00`)
      this.verifyNullableFilter(filter, 'companyCode', this.filters.companyCode)

      this.verifyNullableFilter(filter, 'altStartTime', this.filters.altStartTime, `T00:00:00-03:00`)
      this.verifyNullableFilter(filter, 'altFinishTime', this.filters.altFinishTime, `T23:59:59-03:00`)
      this.verifyNullableFilter(filter, 'nfeNumber', this.filters.nfeNumber)

      return filter
    },
    findKey(object, key, value) {
      Object.keys(object).some(function (k) {
        if (k === key) {
          if (typeof object[k] == 'boolean') {
            object[k] = JSON.parse(value)
          } else {
            object[k] = value
          }
          return true
        }
      })
    },
  },
}
</script>
