<script>
import FilterComponent from '@/components/FilterComponent.vue'

export default {
  extends: FilterComponent,
  name: 'OccurrencesFiltersComponent',
  data() {
    return {
      path: 'occurrences',
    }
  },
}
</script>
